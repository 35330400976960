<template>
  <div class="saleSampleCheck">
    <h1>销售数量校对</h1>
    <el-form :inline="true">
      <el-form-item label="到货日期">
        <el-date-picker v-model="searchParam.sampleDate" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">查询并校对</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-text>校对失败的销售单：</el-text>
        <el-table :data="data.failSaleList" border style="height: calc(50vh - 150px);">
          <el-table-column prop="truckNumber" label="车号" show-overflow-tooltip />
          <el-table-column prop="receiveQuantity" label="数量" width="120" show-overflow-tooltip align="right">
            <template #default="scope">
              {{ math.formatNumber(scope.row.receiveQuantity) }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <el-text>校对失败的取样单：</el-text>
        <el-table :data="data.failSaleSampleList" border style="height: calc(50vh - 150px);">
          <el-table-column prop="truckNumbers" label="车号" show-overflow-tooltip />
          <el-table-column prop="sampleQuantity" label="数量" width="120" show-overflow-tooltip align="right">
            <template #default="scope">
              {{ math.formatNumber(scope.row.sampleQuantity) }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 20px;">
      <el-col :span="12">
        <el-text>校对通过的销售单：</el-text>
        <el-table :data="data.successSaleList" border style="height: calc(50vh - 150px);">
          <el-table-column prop="truckNumber" label="车号" show-overflow-tooltip />
          <el-table-column prop="receiveQuantity" label="数量" width="120" show-overflow-tooltip align="right">
            <template #default="scope">
              {{ math.formatNumber(scope.row.receiveQuantity) }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="12">
        <el-text>校对通过的取样单：</el-text>
        <el-table :data="data.successSaleSampleList" border style="height: calc(50vh - 150px);">
          <el-table-column prop="truckNumbers" label="车号" show-overflow-tooltip />
          <el-table-column prop="sampleQuantity" label="数量" width="120" show-overflow-tooltip align="right">
            <template #default="scope">
              {{ math.formatNumber(scope.row.sampleQuantity) }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()

const data = reactive({
  failSaleList: [],
  failSaleSampleList: [],
  successSaleList: [],
  successSaleSampleList: [],
})

const show = reactive({
  selected: [],
  customList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})

const searchParam = reactive({})
let now = new Date()
searchParam.sampleDate = now
searchParam.custom = null

const commitFindList = () => {
  if (!searchParam.sampleDate) {
    ElMessage.error('请选择日期')
    return
  }
  if (!searchParam.custom) {
    ElMessage.error('请选择客户')
    return
  }
  const params = {}
  params.sampleDate = util.parseTime(searchParam.sampleDate, '{y}-{m}-{d}')
  params.custom = searchParam.custom
  api.post('/backend/saleSample/checkToSale', params).then(res => {
    data.failSaleList = res.failSaleList
    data.failSaleSampleList = res.failSaleSampleList
    data.successSaleList = res.successSaleList
    data.successSaleSampleList = res.successSaleSampleList
  })
}
</script>

<style lang="less"></style>